<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" :cell-class-name="cellClassName">
		<el-table-column type="selection" width="55" :selectable="rowSelectable">
		</el-table-column>
		<el-table-column label="商品id" prop="spu" min-width="120">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="选款规则" prop="rule" min-width="150">
			<template slot-scope="scope">
				{{getRule(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="150">
		</el-table-column>
		<el-table-column label="商品主图" min-width="150">
			<template slot-scope="scope">
				<div v-if="scope.row.image" class="table-img-box">
					<el-image :src="scope.row.image" :preview-src-list="[scope.row.image]"></el-image>
				</div>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import Data from './mixins/data.js'
	export default {
		mixins: [MultipleTableSelection, Data],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		methods: {
			rowSelectable(row, index) {
				return row.image !== null
			}
		}
	}
</script>

<style>
</style>
